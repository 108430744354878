import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Layout from "../components/Layout";
import styles from "../css/blog.module.css";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import Card from "../components/CardBlog";
import SEO from "../components/SEO";

const BlogListTemplate = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { currentPage, numPages } = props.pageContext;
  const { data } = props;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const previousPage =
    currentPage - 1 === 1 ? "/blog" : `/blog/${currentPage - 1}`;
  const nextPage = `/blog/${currentPage + 1}`;

  return (
    <Layout>
      <SEO title="Blog" />
      <StyledHero
        img={data.blogImage.childImageSharp.fluid}
        backgroundColor="0.2"
      >
        <Banner title="Blog" />
      </StyledHero>
      <section className={styles.blog}>
        {!(isFirst && isLast) && (
          <section className={styles.links}>
            {!isFirst && (
              <AniLink fade to={previousPage} className={styles.link}>
                Previous
              </AniLink>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <AniLink
                key={i}
                fade
                to={`/blog/${i === 0 ? "" : i + 1}`}
                className={
                  i + 1 === currentPage
                    ? `${styles.link} ${styles.active}`
                    : `${styles.link}`
                }
              >
                {i + 1}
              </AniLink>
            ))}
            {!isLast && (
              <AniLink fade to={nextPage} className={styles.link}>
                Next
              </AniLink>
            )}
          </section>
        )}
        <div className={styles.center} data-cy="post-list">
          {data.posts.edges.map(({ node }) => (
            <Card key={node.id} node={node} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query getPosts($skip: Int!, $limit: Int!) {
    posts: allContentfulBlogArticle(skip: $skip, limit: $limit) {
      edges {
        node {
          id: contentful_id
          title
          slug
          category {
            name
            slug
          }
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    blogImage: file(relativePath: { eq: "block-island.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default BlogListTemplate;
