import React from "react";
import styled from "styled-components";
import BackgroundImage from "gatsby-background-image";
import { graphql, useStaticQuery } from "gatsby";

const getDefaultImage = graphql`
  {
    file(relativePath: { eq: "women-rowing.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

// eslint-disable-next-line no-unused-vars
const StyledHero = ({ img, className, backgroundColor, children, home }) => {
  const data = useStaticQuery(getDefaultImage);
  const defaultImage = data.file.childImageSharp.fluid;
  const mainImage = img || defaultImage;
  const bg = backgroundColor || 0;

  return (
    <BackgroundImage className={className} fluid={mainImage}>
      <div
        className="background-screen"
        style={{
          backgroundColor: `rgba(41, 60, 99, ${bg} )`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </div>
    </BackgroundImage>
  );
};

export default styled(StyledHero)`
  min-height: 200px;
  background-position: center;
  background-size: cover;
  opacity: 1 !important;
  display: flex;
  justify-content: start;
  align-items: center;
  @media (min-width: 576px) {
    min-height: 450px;
  }
`;
// ${props => (props.home ? "calc(50vh - 62px)" : "50vh")};
